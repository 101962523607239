
import { metaData } from "@/assets/js/common-meta-info";
import { setMetaInfo } from "@/assets/js/meta-info-config";
import { Component, Vue } from "vue-property-decorator";

@Component({
  
})
export default class ComingSoon extends Vue {
 
  mounted() {
    setMetaInfo({title: "Comming Soon"});
}
}
